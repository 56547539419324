export default {
  apiKey: "AIzaSyAL70SN_bnwZwi0WUj7i0yQJZrTUmjmmdk",
  authDomain: "colm-and-katie-wedding.firebaseapp.com",
  databaseURL: "https://colm-and-katie-wedding.firebaseio.com",
  projectId: "colm-and-katie-wedding",
  storageBucket: "colm-and-katie-wedding.appspot.com",
  messagingSenderId: "766349811901",
  appId: "1:766349811901:web:2667c7a699d10d0c2417d4",
  measurementId: "G-0QE53RCE1F",
};
